<template>
  <div class="main">
    <div class="title_box">
      <div class="title_inner">
        <div class="imgInner">
          <img src="../../assets/logo.png" style="height: 100%" />
        </div>

        <div class="title_name">易工厂工单追溯</div>
      </div>
    </div>
    <div class="code_box">
      <div class="title_inner" v-if="listData[0]">
        <div class="title_row flex">
          <div class="code_title">工单编号:{{ listData[0].woCode }}</div>
          <div class="code_state">{{ getState(listData[0].woState) }}</div>
        </div>
        <div class="title_row flex">
          <div class="code_name">产品名称</div>
          <div class="code_data">
            {{ listData[0].productName }}
          </div>
        </div>
        <div class="title_row flex">
          <div class="code_name">计划数量</div>
          <div class="code_data">
            {{ listData[0].planCounts }}
          </div>
        </div>
        <div class="title_row flex">
          <div class="code_name">计划开始时间</div>
          <div class="code_data">
            {{ listData[0].planStartTime }}
          </div>
        </div>
        <div class="title_row flex">
          <div class="code_name">计划结束时间</div>
          <div class="code_data">
            {{ listData[0].planEndTime }}
          </div>
        </div>
        <div class="title_row flex">
          <div class="code_name">创建时间</div>
          <div class="code_data">
            {{ listData[0].createTime }}
          </div>
        </div>
      </div>
    </div>
    <div class="collapse_box">
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item
          v-for="item in stepList"
          :key="item.id"
          :name="item.bsjxCode"
        >
          <template #title>
            <div class="collapse_title">
              <div
                class="title_radio"
                :style="{ backgroundColor: getColor(item.state) }"
              ></div>
              <div>{{ item.wpName }}</div>
            </div>
          </template>
          <div class="collapse_inner">
            <div class="title_row flex">
              <div class="code_name">工序状态</div>
              <div class="code_data">
                {{ getState(item.state) }}
              </div>
            </div>
            <div class="title_row flex">
              <div class="code_name">计划数</div>
              <div class="code_data">
                {{ item.planCounts }}
              </div>
            </div>
            <div class="title_row flex">
              <div class="code_name">良品数</div>
              <div class="code_data">
                {{ item.goodProductCounts }}
              </div>
            </div>
            <div class="title_row flex">
              <div class="code_name">不良品数</div>
              <div class="code_data">
                {{ item.badProductCounts }}
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { getCode } from "@/api/work.js";
export default {
  data() {
    return {
      activeName: "1",
      active: 0,
      woCode: "",
      listData: [],
      stepList: [],
    };
  },
  mounted() {
    console.log("进入页面");
    console.log(this.$route.query.woCode);
    this.woCode = this.$route.query.woCode;
    this.getList();
  },
  methods: {
    async getList() {
      console.log(this.woCode);
      const res = await getCode({}, this.woCode);
      this.listData = [];
      this.listData.push(res.order);
      console.log(this.listData[0].woCode);
      this.stepList = res.processList;
    },
    getState(state) {
      if (state == 0) {
        return "未开始";
      } else if (state == 1) {
        return "执行中";
      } else if (state == 2) {
        return "已结束";
      } else {
        return "已取消";
      }
    },
    getColor(state) {
      if (state == 0) {
        return "#fff";
      } else if (state == 1) {
        return "blue";
      } else if (state == 2) {
        return "green";
      } else {
        return "red";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./approval.less";
/deep/ .van-cell__right-icon {
  opacity: 0;
}
/deep/ .van-cell {
  background-color: #0000;
}
/deep/ .van-collapse-item {
  box-shadow: 0px -4px 16px 4px #ccc;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
/deep/ .van-collapse-item__content {
  background-color: #0000;
}
/deep/ .van-steps {
  background-color: #0000;
}
</style>